import React from "react";
import "./FirstImage.css";
import Image from "../../images/landingPic.webp";

export const FirstImage = () => {
  return (
    <div className="first-image-container">
      <div className="first-image-box">
        <h1>Välkommen till Iconic Brows & Beauty<br/> där skönhet möter perfektion! </h1>
        <p className="first-image-box-text">
        Jag brinner för att förbättra din naturliga skönhet och hjälpa dig att se ut och känna dig som bäst. Kliv in i ett fridfullt och inbjudande rum och skämm bort dig med en rad lyxiga skönhetsbehandlingar som kosmetisk tatueringar, lashlift, brow lamination och ansiktsbehandlingar.        </p>
        <a
          className="first-image-box-button"
          href="https://www.bokadirekt.se/places/iconic-brows-beauty-45783"
          target="_blank"
        >
          Boka Behandling Direkt
        </a>
      </div>
    </div>
  );
};
